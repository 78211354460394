import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import Photo from '../components/Photo';
import "../styles/dashboard.scss";
import "../styles/common_style.scss";
import { navigate } from "gatsby";
import { Trans } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";
import { Link, I18nextContext } from "gatsby-plugin-react-i18next";
import { Helmet } from "react-helmet";

const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL;

export default function Dashboard() {
    const { language } = React.useContext(I18nextContext);
    const [fullName, setFullName] = useState("");
    const buildCanonicalUrl = () =>
        language === "en"
            ? GATSBY_LANDING_PAGE_URL + "/dashboard/"
            : GATSBY_LANDING_PAGE_URL + `/${language}/dashboard/`;
    const canonicalUrl = buildCanonicalUrl();

    const buildHrefUrl = () =>
    language === "en"
      ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
      : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL +`/${language}/`))
  const hrefUrl = buildHrefUrl();

    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('userinfo'));

        if (localStorage.getItem('userinfo')) {
            setFullName(userData.fullName);
        } else {
            let currentLanguage = `${language}`;

            if (currentLanguage === 'en') {
                navigate('/');
            }
            else {
                navigate(`/${currentLanguage}/`);
            }
        }
    }, [language]);

    const submitHandler = () => {
        let currentLanguage = `${language}`;

        if (currentLanguage === 'en') {
            navigate("/login");
        } else {
            navigate(`/${currentLanguage}/login`);
        }

        localStorage.removeItem("userinfo");
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dashboard</title>
                <Link rel="canonical" href={canonicalUrl} />
                <Link rel="alternate" href={hrefUrl} hreflang={language} />
                <meta name="description" content="Logipulse Dashboard" />
                <meta name="Keywords" content="web development, companypage" data-react-helmet="true" />
            </Helmet>
            <main class="purchase-page height_100_percent_page" lang={language}>
                <div class="purchase-page-div height_100_percent_page_div" lang={language}>
                    <div class="container">
                        <div class="row purchase-total" lang={language}>
                            <div class="col-md-6">
                                <div class="contents ipaddashboard" lang={language}>
                                    <Link to="/" language={language}>{" "}<Photo src="LogiPulse-Logo-H48.svg" className="photo-size" alt="" />{" "}</Link>
                                    <p><Trans>Congratulations on becoming a synergist in digitizing your logistics business. The express journey that begins here is going to make you escalate above the horizon you envisioned.</Trans></p>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div className="contents" lang={language}>
                                    {/* <!-- multistep form --> */}
                                    <div className="saas-head" lang={language}><Trans>Welcome!</Trans></div>
                                    <div className="name" lang={language}>{fullName}</div>
                                    <div className="saas-sub-head" lang={language}>
                                        <div><FontAwesomeIcon icon={faCheckCircle} className="tick" /></div>
                                        <div><Trans>Your LogiPulse SASSboard has been successfully created!</Trans></div>
                                    </div>
                                    <div className="saas-content" lang={language}>
                                    </div>
                                    <div className="saas-dash-parent" lang={language}>
                                        <div className="dashboard-icons" lang={language}><FontAwesomeIcon icon={faAngleDoubleRight} /></div>
                                        <button  className="color_blue remove_default_btn_style margin_left_8" onClick={submitHandler}><Trans>Get me in to my SAAS dashboard</Trans></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <footer className="purchase-footer">
                    <div class="purchase-footer-bottom">
                        <div class="container">

                            <div className="row align-content-center">
                                <div class="col-md-4 power">
                                    <div><Trans>Powered by</Trans></div>
                                    <Photo src="Stellosysyw-Logo.svg" alt="powered_logo" className="powered_logo" />
                                </div>
                                <div class="col-md-4 copyright">
                                    <div>
                                        <Trans>Copyright 2021 Stellosys</Trans><br /> <Trans>All rights Reserved.</Trans>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </footer>
            </main>
        </div>
    )
}


export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`